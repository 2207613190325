<template>
  <layout-page :page-description="pageDescription">
    <template v-slot:toolbar-title>
      <v-toolbar-title>{{apiKey}}<small> - {{translate('API key')}}</small></v-toolbar-title>
    </template>
    <template v-slot:toolbar-items>
      <v-btn
        text
        :loading="user.loading"
        :disabled="!valid"
        @click="save"
        >
        <v-icon>save</v-icon> {{translate('Save')}}
      </v-btn>
    </template>

    <v-card>
      <v-card-text>
        <v-form v-model="valid">
          <v-text-field :label="translate('API Key')" v-model="user.apiKey" :disabled="true" :loading="user.loading"/>
          <v-text-field :label="translate('Description')" v-model="user.description" :enabled="false" :loading="user.loading"/>

          <v-checkbox v-for="role in roleNames" v-model="user.roles[role]" :label="role" :loading="user.loading" :key="role"/>

          <v-text-field :label="translate('Valid from')" type="date" v-model="user.valid.from" :loading="user.loading"/>
          <v-text-field :label="translate('Valid to')" type="date" v-model="user.valid.to" :loading="user.loading"/>
          <v-text-field
            :label="translate('CIDR')"
            v-model="user.valid.cidr"
            :rules="validationRules.cidr({optional: true})"
            pattern="((^|\.)((25[0-5])|(2[0-4]\d)|(1\d\d)|([1-9]?\d))){4}$"
            :loading="user.loading"
            />
        </v-form>
      </v-card-text>
    </v-card>
  </layout-page>
</template>
<script>
import {mapGetters} from 'vuex'
import LayoutPage from '@/components/layout/layout-page.vue'

export default {
  props: ['apiKey'],
  data: () => ({
    mutations: 1,
    valid: true
  }),
  components: {
    LayoutPage
  },
  mounted () {
  },
  computed: {
    ...mapGetters(['api', 'translate', 'validationRules', 'createPageDescription']),
    roleNames () {
      return Object.keys(this.user.roles)
    },
    pageDescription () {
      const {apiKey} = this
      return this.createPageDescription()
        .addBreadcrumb({ text: this.translate('API Keys'), to: {name: 'api-keys'}})
        .addBreadcrumb({text: apiKey, to: {name: 'edit-api-key', params: {apiKey}}})
    },
  },
  asyncComputed: {
    user: {
      async get () {
        let user = this.mutations && await this.api.apiKeys.getApiKeyUser(this.apiKey)
        return {
          ...user,
          loading: false
        }
      },
      default: {
        loading: true,
        roles: {},
        valid: {from: null, to: null, cidr: null}
      }
    }
  },
  methods: {
    async save () {
      await this.api.apiKeys.updateApiKeyUser(this.user)
      this.mutations = this.mutations + 1
    }
  }
}
</script>
